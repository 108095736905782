<template>
    <div class="success">
        <!-- <div style="border-radius:200px; height:100px; width:100px; border:3px solid #C98BDB; margin:0 auto;">
          <i class="checkmark">✓</i>
        </div> -->
        <div class="filler-flex">
          <div class="flex-fill">
            <h1>Success</h1> 
            <p>You’re now officially a waste warrior! Your mystery box will be ready soon.</p>
          </div>
          <div class="flex-nofill">
            <v-button @click="redirect()">View order details</v-button>
          </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/misc/ButtonComponent'
import router from '@/router'
import store from '@/store/index.js';
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('cartModule');

export default{
  components: {
    'v-button': Button
  },
  methods:{
    ...mapActions(['cleanCart']),
    redirect(){
      router.push({ name: 'order', params: { uuid: this.$route.query.order_uuid } })
    }
  },
  mounted(){
    this.cleanCart();
  }
}
</script>

<style scoped>
.filler-flex{
  display:flex;
  height:100%;
  flex-direction: column;
}
.flex-fill{
  flex: 1 1 auto;
}
.flex-nofill{
  margin-top:100px;
  flex:0 0 auto;
}
.success{
  margin-top:75px;
  position:absolute;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
h1 {
  color: black;
 font-weight: 800;
  font-size: 40px;
  margin-bottom: 10px;
}
p,a {
  color: #2c3e50;
  font-size:20px;
  margin: 0;
}
.checkmark {
color: #9ABC66;
font-size: 50px;
line-height: 100px;
margin-left:-15px;
}
</style>