import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'maz-ui/css/main.css'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import store from './store'
import topnav from '@/components/navigation/TopNav'
import MazBtn from 'maz-ui/components/MazBtn'
import MazInput from 'maz-ui/components/MazInput'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import MazInputPrice from 'maz-ui/components/MazInputPrice'
import MazCheckbox from 'maz-ui/components/MazCheckbox'
import MazInputNumber from 'maz-ui/components/MazInputNumber'
import BootstrapVue3 from 'bootstrap-vue-3'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueLazyLoad from 'vue3-lazyload'
// Optional, since every component import their Bootstrap functionality
// the following line is not necessary
// import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import '@/assets/js/grab-to-scroll-module.js'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faLocationDot, faCircleUser, faShoppingBag } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faLocationDot)
library.add(faCircleUser)
library.add(faShoppingBag)

const app = createApp(App).use(router)
app.component("top-nav", topnav)
app.component('MazBtn', MazBtn)
app.component('MazInputNumber', MazInputNumber)
app.component('MazInput', MazInput)
app.component('MazInputPrice', MazInputPrice)
app.component('MazCheckbox',MazCheckbox)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('MazPhoneNumberInput', MazPhoneNumberInput)
app.use(store)
app.use(ToastPlugin, {
    // One of the options
    position: 'top'
})
app.use(VueLazyLoad);
app.use(BootstrapVue3)
app.mount('#app')