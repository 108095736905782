<template>
<Transition>
<div class="cart-wrapper module black" @click="redirect" v-if="getQuantity > 0">
  <div class="cart-button">
    <font-awesome-icon icon="fa-solid fa-shopping-bag" />
    <div class="number">
      {{ getQuantity }}
    </div>
  </div>
    <div class="cart-information">
      <div class="upper">My Bag</div>
      <div class="lower">Checkout here</div>
    </div>
</div>
</Transition>
</template>


<script>
import store from '@/store/index.js';
import router from '@/router'
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('cartModule');
export default {
  
  name: "cart-icon",
  methods: {
    redirect(){
      router.push({ name: 'cart_display' })
    }
  },
  computed: {
    getQuantity(){
      var quantity = store.getters["cartModule/getTotalQuantity"]
      return quantity;
    },
  }
};
</script>

<style scoped>
.cart-wrapper{
  z-index:10;
  width:100%;
  max-width:300px;
  min-height:32px;
  position:fixed;
  border-radius:50px;
  bottom:32px;
  gap:2em;
  display:flex;
  cursor: pointer;
  text-align: start;
}
.cart-wrapper .cart-button{
  border-radius:10000px;
  width:48px;
  height:48px;
  background-color:white;
  position:relative;
  color:black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:28px;
  cursor: pointer;
}
.cart-wrapper .cart-button .number{
  font-size:16px;
  bottom:-6px;
  right:-6px;
  width:30px;
  height:30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius:100px;
  color:black;
  background:#F2DF2D;
  font-weight:800;
  position: absolute;
}
.cart-wrapper .cart-information{
  display:flex;
  flex-direction: column;
}
.cart-wrapper .cart-information .upper{
  font-weight:800;
  font-size:16px;
}
.cart-wrapper .cart-information .lower{
  color:#8A8A8A;

}
</style>