<template>
    <div class="product-wrapper module" @click="openManagementModal()">
        <div class="product-title">{{product.title}}</div>
        <div class="product-image">
            <img :src="product.image"/>
        </div>
    </div>
    <div>
    <vue-final-modal v-model="management_modal" classes="modal-container" content-class="modal-content">
        <span class="modal__title">Edit {{product.title}}</span>
        <div class="modal__content" style="margin-top:1em;">
            <product-management-modal :product="product" />
        </div>
    </vue-final-modal>
    </div>
</template>


<script>
import { ref, onMounted  } from 'vue';
import '@vuepic/vue-datepicker/dist/main.css'
import apiClient from "@/apiClient.js" 
import constants from "@/apiConstants.js"
import Button from '@/components/misc/ButtonComponent'
import ProductManagementModal from "@/components/vendor/management/product/ProductManagementModal.vue"
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'


export default {
    
    name:"product-display-box",
    props:["product"],
    components:{
        ProductManagementModal,
        VueFinalModal,
        ModalsContainer,
    },
    data(){
        return {
            management_modal: false,
        }
    },
    methods:{
        openManagementModal(){
            this.management_modal = true;
        }
    }
}
</script>
<style scoped>
.product-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:1.25em;
    background-color:black;
    width:150px;
    flex: 0 0 auto;
    height:150px;
}

.product-wrapper .product-title{
    color:white;
    font-size:12px;
    font-weight:800;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-wrapper .product-image img{
    width:auto;
    min-width:1px;
    height:70px;
    margin-top:1em;
}

.product-wrapper .product-image{
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>