export default {
    getToken(state){
        return state.token
    },
    getUserId(state){
        return state.id
    },
    isLoggedIn(state){
        return state.loggedInUser
    },
}