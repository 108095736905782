<!-- BaseInput.vue component -->

<template>
    <div>
        <h2>Select Quantity</h2>
        <MazInputNumber
            v-model="modelValue"
            label="Enter Quantity"
            :min="1"
            :max="availableQuantity"
            :step="1"
            size="md"
            color="secondary"
            @update="updateValue()"
        />
    </div>
</template>

<script>
export default {
    name: "quantity-component",
    props: {
        modelValue: Number,
        "availableQuantity": String
    },
    methods: {
        updateValue(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    },
}
</script>