<template>
    <div class="location-module module" @click="showAddressModal">
        <div class="location-content">
            <div class="module black bold">Find Near Me</div>
            <div v-if="address" class="bold">{{ address }}</div>
            <div v-else class="address-empty">Enter zip code</div>
        </div>
    </div>
    <div>
    <vue-final-modal v-model="address_modal_show" classes="modal-container" content-class="modal-content">
        <span class="modal__title" style="margin-bottom:1em;"></span>
        <div class="modal__content">
            <div class="align-center location-information">
                <fieldset class="fields">
                    <input type="text" ref="zip_code" name="zipcode" v-model="address" placeholder="Zip code" class="input">
                </fieldset>
                <div>
                    <v-button @click="setLocation" style="background-color:transparent; color:black;">Save Location</v-button>
                </div>
            </div>
        </div>
    </vue-final-modal>
    </div>
</template>

<script>
import axios from "axios"
import Button from '@/components/misc/ButtonComponent'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

export default {
  name: 'user-location-modal',
  props:['id'],
  components: {
    VueFinalModal,
    ModalsContainer,
    "v-button": Button
  },
  data(){
    return{
       address: null,
       zip_code: null,
       longitude: null,
       latitude: null,
       address_modal_show: false,
    }
  },
  methods: {
    async setLocation(){
        this.hideAddressModal()
        var url= "https://maps.googleapis.com/maps/api/geocode/json?address=" + this.address + "&key=AIzaSyCagObxVrnw8IwGuj4KZ7CabcYRY2y-tJE"
        console.log(url)
        const response = await axios.get(url);
        const results = response.data.results[0]
        this.longitude = results.geometry.location.lng
        this.latitude = results.geometry.location.lat
        this.$emit('update', this.longitude, this.latitude)
    },
    getCurrentLocation(){

    },
    showAddressModal(){
        this.address_modal_show = true;
    },
    hideAddressModal(){
        this.address_modal_show = false;
    },
  },
  watch:{
    address_modal_show: function(){
        if(this.address_modal_show){
            setTimeout(x => {
                this.$nextTick(() => this.$refs.zip_code.focus()); // this works great, just watch out with going to fast !!!
            }, 250);
        }
    }
  },
}
</script>

<style scoped>
.location-module, .location-information{
    display:flex;
    flex-direction: column;
    gap:1em;
    flex: 1 1 auto;
    width:100%;
    max-width:400px;
}
.location-module{
    cursor:pointer;
}
.bold{
    font-weight:800;
}
.address-empty{
    color:#E8E8E8;
}
.location-information{
    max-width:unset;
    align-items: center;
}


.location-content{
    align-items: center;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap:1em;
}
.location-content div{
    flex: 1 1 auto;
}

</style>