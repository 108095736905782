<template>
    <div class="location-module module" @click="showAddressModal">
        <div class="location-content">
            <div class="module black stacked">
                <p class="upper">Select Pickup Location</p>
                <p class="lower">{{address}}</p>
            </div>
            <div class="module stacked" style="padding-left:0em; padding-right:0em;">
                <p class="upper">Time</p>
                <p class="lower time">{{display_opening_time}}-{{display_closing_time}}</p>
            </div>
        </div>
    </div>
    <div>
    <vue-final-modal v-model="address_modal_show" classes="modal-container" content-class="modal-content">
        <span class="modal__title">Please select a location</span>
        <div class="modal__content" style="margin-top:1em;">
            <vendor-location-component v-for="(location,index) in locations" :id="index" @click="setLocation(location.id)" v-bind:location="location" v-bind="locationProps(location.id,true)"/>
        </div>
    </vue-final-modal>
    </div>
</template>

<script>
import axios from "axios"
import Button from '@/components/misc/ButtonComponent'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import VendorLocationComponent from '@/components/vendor/VendorLocationComponent.vue'

export default {
  name: 'pickup-delivery-location',
  emits: ["update"],
  props:['selected_location','locations'],
  components: {
    VueFinalModal,
    ModalsContainer,
    VendorLocationComponent,
    "v-button": Button
  },
  data(){
    return{
       address: null,
       zip_code: null,
       longitude: null,
       latitude: null,
       address_modal_show: false,
       opening_time: null,
       closing_time: null,
       display_opening_time:null,
       display_closing_time:null,
    }
  },
  methods: {
    setLocation(location_id){
        this.$emit('update', location_id)
        this.updateTime();
        this.hideAddressModal()
    },
    updateTime(){
        var d=new Date();
        var locationTimeData;
        var objDict = this.selected_location.pickup_times
        Object.keys(objDict).forEach(x => locationTimeData = objDict[x].day === d.getDay().toString() ? objDict[x]: locationTimeData);
        if(locationTimeData){
            this.opening_time = locationTimeData.opening_time
            this.closing_time = locationTimeData.closing_time
            this.display_opening_time = moment.utc(this.opening_time).local().format('h:mma')
            this.display_closing_time = moment.utc(this.closing_time).local().format('h:mma')
        }else{
            //If the store doesn't have any time set for this day, this is the message that will be displayed
            this.display_opening_time = "Closed"
            this.display_closing_time = ""
        }
    },
    locationProps(location_id, in_modal) {
        const result = {};
        result.in_modal = in_modal;
        result.active = false;
        if(location_id==this.selected_location.id){
            result.active=true;
        }
        return result
    },
    showAddressModal(){
        this.address_modal_show = true;
    },
    hideAddressModal(){
        this.address_modal_show = false;
    },
  },
  computed:{
    address(){
        var address = this.selected_location.address;
        console.log(address.split(',')[0])
        return address.split(',')[0]
    }
  },
    mounted() {
        this.updateTime();
    },
}
</script>

<style scoped>
.location-module, .location-information{
    display:flex;
    flex-direction: column;
    gap:1em;
    flex: 1 1 auto;
    width:100%;
    max-width:400px;
}
.location-module{
    cursor:pointer;
}
.bold{
    font-weight:800;
}
.address-empty{
    color:#E8E8E8;
}
.location-information{
    max-width:unset;
    align-items: center;
}


.location-content{
    align-items: center;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap:1em;
}

.stacked{
    align-items: center;
    display:flex;
    flex:1 1 auto;
    flex-direction: column;
    justify-content: space-between;
}

.upper{
    font-size:14px;
    font-weight:700;
    text-align: center;
}
.lower{
    text-align: center;
    font-size:12px;
}

.time{
    color:#C98BDB;
    font-weight:800;
}

/* MODAL STYLING */

.modal__content.flex{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:1em;
    overflow-y: auto;
}
</style>