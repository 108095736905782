<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <!-- <b-form-group>
        <MazPhoneNumberInput
            v-model="phone_number"
            show-code-on-list
            color="info"
            :preferred-countries="['FR', 'BE', 'DE', 'US', 'GB']"
            :ignored-countries="['AC']"
            @update="results = $event"
            :success="results?.isValid"
        />
      </b-form-group> -->
      <b-form-group>
        <MazInput v-model="email" label="Email Address" />
      </b-form-group>
      <b-form-group>
        <MazInput v-model="password" label="Insert Password" type="password" />
      </b-form-group>
      <code>
        {{error_message}}
        <br>
      </code>
      <b-button type="submit" variant="primary" style="margin-top:25px;">Submit</b-button>
      <br/>
      <br/>
    </b-form>
  </div>
</template>

<script>

import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import { ref } from 'vue'
import { createNamespacedHelpers, mapMutations } from "vuex";
const { mapActions } = createNamespacedHelpers("authModule");

export default {
  name: "login-form",
  components:{
    MazPhoneNumberInput,
  },
  data() {
    return {
      phone_number: '',
      email: '',
      password: '',
      error_message: "",
    }
  },
  methods: {
    ...mapActions(["submitLogin"]),
    onSubmit() {
      this.submitLogin({phone_number: this.phone_number, password: this.password, email:this.email}).then((response) => {
        if(response){
          this.$emit("success");
        }else{
          //COULDN'T LOG IN
          this.error_message = "Incorrect login information"
        }
      });
    }
  },
  mounted() {
  },
};
</script>