<template>
  <vendor-display-page :id="this.$route.params.vendor_id"/>
</template>

<script>
import axios from "axios"
import VendorDisplayPage from "@/components/vendor/VendorDisplayPage"
import CartIcon from "@/components/cart/CartIcon.vue"

export default {
  name: 'VendorDisplayView',
  params:['vendor_id'],
  components: {
    VendorDisplayPage,
    CartIcon
  },
  data(){
    return{
        id: this.$route.params.vendor_id,
    }
  },
}
</script>