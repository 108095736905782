<template>
  <div @click="redirect" class="company-display module" :class="quantity<1 ? 'sold-out' : '' " >
        <img class="image" v-lazy="this.image_src">
        <quantity-box-component :quantity="quantity" :visible="quantity_visible"></quantity-box-component>
    </div>
</template>

<script>
import axios from "axios"
import QuantityBoxComponent from '@/components/vendor/QuantityBoxComponent'
import router from '@/router'

export default {
  name: 'vendor-display-component',
  props:{'id':Number,
  quantity_visible:{
      type:Boolean,
      default: true,
    },
    'slug':String, 
    'image_src':String, 
    'quantity':Number,
    'location_id':{type:Number,default:null}},
  components:{
    QuantityBoxComponent
  },
  methods:{
    redirect(){
      if(this.location_id){
        router.push({ name: 'vendor_display', params: { vendor_id: this.id }, query: {"location":this.location_id} })
      }else{
        router.push({ name: 'vendor_display', params: { vendor_id: this.id } })
      }
    }
  }
}
</script>

<style scoped>
.company-display{
  width:100%;
  height:164px;
  position: relative;
  overflow:hidden;
  max-width:400px;
  cursor: pointer;
}
.company-display .image{
  width:100%;
  height:100%;
  object-fit: contain;
  left:0;
  top:0;
  position: absolute;
}

.company-display .quantity{
  position: absolute;
  top:20px;
  left:20px;
  padding:0.5em 0.75em 0.5em 0.75em;
  font-size:0.75em;
  color:white;
  font-weight: 700;
  background-color:black;
  border-radius:32px;
}

.sold-out{
  opacity:0.25;
}
</style>