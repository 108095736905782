<template>
    <button class="button">
        <slot></slot>
    </button>
</template>
<style scoped>
.button{
    padding:0.45em 1em 0.45em 1em;
    border-radius:48px;
    background-color:black;
    color:white;
    font-size:20px;
    min-width:150px;
 font-weight: 800;
}
</style>