export default{
  getItems(state) {
    return state.cart;
  },
  getProductQuantity: (state) => (id) => {
    var quantity = 0
    if(state.cart[id]){
      quantity = state.cart[id]
    }
    return quantity;
  },
  getTotalQuantity(state) {
    var quantity = 0
    for (let x in state.cart) {
      quantity += state.cart[x] 
    };
    return quantity;
  },
}