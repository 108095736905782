<template>
    <div class="order-wrapper module">
        <div class="order-name">{{order.name}}</div>
        <div>
            <div class="order-title">{{order.product_title}}</div>
            <div class="order-quantity">{{order.quantity}}x</div>
        </div>
    </div>
</template>


<script>
import { ref, onMounted  } from 'vue';
import '@vuepic/vue-datepicker/dist/main.css'
import apiClient from "@/apiClient.js" 
import constants from "@/apiConstants.js"
import Button from '@/components/misc/ButtonComponent'

export default {
    
    name:"order-display-box",
    props:["order"],
    components:{
        "v-button": Button,
    },
    data(){

    },
    methods:{

    }
}
</script>
<style scoped>
.order-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:1.25em;
    gap:1em;
    background-color:black;
    height:150px;
}

.order-wrapper .order-name{
    color:white;
    font-weight:800;
}
.order-wrapper .order-quantity, .order-wrapper .order-title{
    color: #fff;
    font-size: 12px;
    font-weight: 800;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.order-wrapper .order-image img{
    width:100px;
    height:auto;
}

.order-wrapper .order-image{
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>