<template>
    <div>
    <vue-final-modal :clickToClose="false" @closed="hideAuthModal" v-model="show" classes="modal-container" content-class="modal-content">
        <span class="modal__title">Please Login</span>
        <div class="modal__content">
            <login-form @success="success"></login-form>
        </div>
    </vue-final-modal>
    </div>
</template>

<script>
import LoginForm from './LoginForm';
import { createNamespacedHelpers } from "vuex";
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
const { mapState, mapActions } = createNamespacedHelpers("authModule");
export default {
    data: () => ({
    show: false
  }),
  name: 'auth-modal',
  components: {
    LoginForm,
    VueFinalModal,
    ModalsContainer
  },
  computed: {
    ...mapState({
      visibility: state => state.authModal.visibility,
      callbackAction: state => state.authModal.callbackAction
    })
  },
  watch: {
    visibility(newVal) {
        let authModalElement = this.$refs.auth_modal;
        newVal
        ? this.show=true
        : this.show=false;
    }
  },
  methods: {
    ...mapActions(["hideAuthModal"]),
    callback() {
      if(this.callbackAction.name){
        this.$store.dispatch(
          this.callbackAction.name,
          this.callbackAction.payload
        );
      }
    },
    success() {
      this.callback();
      this.hideAuthModal();
    },
    reset(){
      this.hideAuthModal();
    }
  },
  beforeMount(){
    this.reset()
  }
};
</script>


<style scoped>
.dark-mode div .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>