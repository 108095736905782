<template>
<div class="title-container">
  <MazInput v-model="title" style="border:unset !important;" />
  <v-button @click="updateTitle()" style="max-width: 200px; width:200px; min-width:unset; font-size:16px;">Update Title</v-button>
</div>
</template>


<script>
import { ref, onMounted  } from 'vue';
import '@vuepic/vue-datepicker/dist/main.css'
import apiClient from "@/apiClient.js" 
import {useToast} from 'vue-toast-notification';
import constants from "@/apiConstants.js"
import Button from '@/components/misc/ButtonComponent'

export default {
    
    name:"product-title-picker",
    props:["product"],
    components:{
        "v-button": Button
    },
    data(){
        return{
            title: this.product.title,
        }
    },
    methods:{
        async changeTitle(payload){
            const url = constants.API_BASE + constants.product.BASE + this.product.id + constants.product.UPDATE_TITLE
            apiClient.post(url, payload).then(response=>{
                const $toast = useToast();
                $toast.success('Title successfully updated.', {
                // optional options Object
                })
            })
        },
        updateTitle(){
            this.changeTitle({title:this.title})
            this.product.title = this.title;
        }
    }
}
</script>
<style scoped>
.title-container{
    display:flex;
    gap:1em;
}
</style>