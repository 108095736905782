<template>
    <div class="vendor-wrapper" v-if="locations">
        <div>
        <vue-final-modal v-model="location_modal_show" classes="modal-container" content-class="modal-content">
            <span class="modal__title">Please select a location</span>
            <div class="modal__content" style="margin-top:1em;">
                <vendor-location-component @click="selectLocation(index)" v-for="(location,index) in locations" :id="index" v-bind:location="location" v-bind="locationProps(location.id,true)"/>
            </div>
        </vue-final-modal>
        </div>
        <div class="module" v-if="selected_location">
            <vendor-location-component @click="showLocationModal" v-bind:location="selected_location"/>
        </div>
        <div v-if="selected_location">
            <vendor-location-management :location_id="selected_location.id"/>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import VendorLocationManagement from "@/components/vendor/management/VendorLocationManagement.vue"
import VendorLocationComponent from '@/components/vendor/VendorLocationComponent.vue'
import store from "@/store/index.js"
import apiClient from "@/apiClient.js" 
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import router from "@/router"

import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('authModule');
import constants from "@/apiConstants.js"

export default {
  name: 'VendorManagementPortal',
  components: {
    VueFinalModal,
    ModalsContainer,
    VendorLocationComponent,
    VendorLocationManagement
  },
  data(){
    return{
        locations: null,
        selected_location: null,
        location_modal_show: false,
        account_id: null,
    }
  },
  methods: {
    async fetchLocations(){
        if (store.getters["authModule/isLoggedIn"]) { 
            try{
                const url = constants.API_BASE + constants.account.BASE + this.account_id
                const response = await axios.get(url);
                const results = response.data
                var location_number = 0
                if(this.$route.query.location){
                    location_number = parseInt(this.$route.query.location)
                }
                this.selected_location = results.locations[location_number]
                this.locations = results.locations
                
            }catch(err){
                if (err.response) {
                    // client received an error response (5xx, 4xx)
                    console.log("Server Error:", err)
                } else if (err.request) {
                    // client never received a response, or request never left
                    console.log("Network Error:", err)
                } else {
                    console.log("Client Error:", err)
                }
            };
        }
    },
    showLocationModal(){
        this.location_modal_show = true;
    },
    hideLocationModal(){
        this.location_modal_show = false;
    },
    selectLocation(index){
        this.selected_location = this.locations[index];
        router.push({ query: {"location":index} }).catch(failure => false);
        this.hideLocationModal();
    },
    locationProps(location_id, in_modal) {
        const result = {};
        result.in_modal = in_modal;
        result.active = false;
        if(location_id==this.selected_location.id){
            result.active=true;
        }
        return result
    },
  },
  beforeMount(){
    this.account_id = store.getters["authModule/getUserId"]
  },
  mounted() {
        if (!store.getters["authModule/isLoggedIn"]) { 
            store.dispatch( 
            "authModule/showAuthModal",
            {
              callbackAction: {name: null, payload:null}
            },
            { root: true }
          );
            return Promise.resolve();
        }else{
            this.fetchLocations();
        }
    },
}
</script>
<style scoped>
.vendor-wrapper{
    display: flex;
    flex-direction: column;
    gap:1em;
}

@keyframes translate-y-in{
  0%{transform: translateY(-100%); opacity:0;}
  100%{transform: translateY(0%); opacity:1;}
}

@keyframes translate-y-out{
  0%{transform: translateY(0%); opacity:1;}
  100%{transform: translateY(-100%); opacity:0;}
}
.translate-y-full{
  transition: translate-y-in 0.2s;
}
.translate-y-0 {
  transition: translate-y-out 0.2s;
}
</style>