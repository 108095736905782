<template>
  <div class="">
    <form @submit.prevent="handleSubmit">
      <fieldset :class="{ dis: loading }" class="fields">
        <input name="chrome-autofill-dummy2" style="display: none" disabled />
        <div class="input-group-stripe">
          <input
            type="text"
            name="name"
            placeholder="Name on card"
            class="input"
          />
          <input
            type="text"
            name="phone"
            placeholder="Phone number"
            class="input"
          />
        </div>
        <div id="stripe-element-mount-point" class="input" />
        <code>{{ error_message }}</code>
        <p class="subtitle">
          *By clicking "PAY" you agree to having a message sent to the phone
          number provided containing order details.
        </p>
        <v-button :class="{ dis: loading }">PAY</v-button>
      </fieldset>
    </form>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import MazInput from "maz-ui/components/MazInput";
import Button from "@/components/misc/ButtonComponent";
import store from "@/store/index.js";
import constants from "@/apiConstants.js";

const phoneNumber = ref();
const inputValue = ref();
const results = ref();

const style = {
  theme: "flat",
  style: {
    base: {
      iconColor: "#FFF",
      color: "#000",
      fontFamily: "'Source Sans Pro'",
      fontSize: "18px",
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "red",
    },
  },
};
const appearance = {
  theme: "flat",

  variables: {
    colorPrimary: "#000000",
    colorBackground: "#F4F4F4",
    colorDanger: "#df1b41",
    fontFamily: "'Source Sans Pro', sans-serif",
    spacingUnit: "0.25em",
    borderRadius: "2.5em",
    // See all possible variables below
  },
};
const fonts = [
  {
    cssSrc:
      "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap",
  },
];

export default {
  name: "checkout-modal",
  props: {
    products: {
      type: Object,
      default: () => ({}),
    },
    prop_client_secret: {
      type: String,
      default: null,
    },
    prop_order_id: {
      type: String,
      default: null,
    },
  },
  components: {
    MazPhoneNumberInput,
    MazInput,
    "v-button": Button,
  },
  methods: {},
  data() {
    return {
      client_secret: null,
      order_uuid: null,
    };
  },
  setup(props) {
    const router = useRouter();
    let stripe = null;
    let error_message = "";
    let clientSecret = props.prop_client_secret;
    let loading = ref(true);
    let elements = null;
    let element = null;
    let order_id = props.order_id;
    onMounted(async () => {
      if (!clientSecret) {
        const response = await fetch(
          constants.API_BASE + "payment/create_payment_intent/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify({ products: props.products }),
          }
        );
        var data = await response.json();
        clientSecret = data.client_secret;
        order_id = data.order_uuid;
      }
      const ELEMENT_TYPE = "payment";
      stripe = await loadStripe(constants.STRIPE_PUBLISHABLE_KEY);
      elements = stripe.elements({ appearance, clientSecret, fonts });
      element = elements.create(ELEMENT_TYPE, style, fonts);
      //let address_element = elements.create('address',style, fonts);
      element.mount("#stripe-element-mount-point");
      loading.value = false;
    });
    async function handleSubmit(event) {
      if (loading.value) return;
      loading.value = true;
      const { name, phone } = Object.fromEntries(new FormData(event.target));

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url:
            "https://app.themunchmore.com/success/?order_uuid=" + order_id,
          payment_method_data: {
            billing_details: {
              name: name,
              phone: phone,
            },
          },
        },
      });
      if (error) {
        error_message = "Payment didn't process, use a different card.";
        loading.value = false;
      }
    }
    return { loading, handleSubmit, error_message };
  },
};
</script>
<style scoped>
.input-group-stripe {
  display: flex;
  flex: 1;
  gap: 1em;
}
.input-group-stripe .input {
  min-width: 0;
  flex: 1;
}

.dis {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
</style>
