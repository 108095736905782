<template>
<div class="vendor-location-wrapper " :class="{ 'active' : active === true, 'in_modal' : in_modal === true}">
    <div class="vendor-location" v-if="location">
        <font-awesome-icon icon="fa-solid fa-location-dot" />
        <p class="address">{{location.address}}</p>
        <quantity-box-component :quantity="location.total_quantity"/> 
    </div>
</div>
</template>

<script>
import axios from "axios"
import QuantityBoxComponent from '@/components/vendor/QuantityBoxComponent'


export default {
  name: 'vendor-location-component',
  props:{
    location: Object,
    active: {
      type: Boolean,
      default: false,
    },
    in_modal:{
      type: Boolean,
      default: false,
    },
    },
  components: {
    QuantityBoxComponent,
  },
  methods:{

  },
  mounted(){
    
  }
}
</script>

<style scoped>
.vendor-location-wrapper{
  width:100%;
  background-color:transparent;
  height:auto;
}

.vendor-location-wrapper .vendor-location{
  width:100%;
  padding:0.75em 1em 0.75em 1em;
  background-color:white;
  border-radius:32px;
  overflow-wrap: break-word;
  height:auto;
  position: relative;
  text-align: left;
  display:flex;
  align-items:center;
}
.vendor-location-wrapper .address{
  color:#585858;
  flex:1 1 auto;
  margin:0px;
  font-size:18px;
  margin:0px 1em;
}
.vendor-location-wrapper .quantity{
  position: relative;
  top:unset;
  right:unset;
  left:unset;
  flex: 0 0 auto;
}

.vendor-location-wrapper.in_modal{
  padding:0em 0em 0em 0em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom:1em;
}

.vendor-location-wrapper.in_modal .vendor-location{
  background-color:#F5F5F5;
}

.vendor-location-wrapper.active .vendor-location{
  background-color:black;
}
.vendor-location-wrapper.active .address{
  color:white;
}
.vendor-location-wrapper.active .quantity{
  background-color:white;
  color:black;
}
.vendor-location-wrapper.active svg{
  filter: invert(100%) sepia(100%) saturate(15%) hue-rotate(252deg) brightness(103%) contrast(100%);
}
</style>