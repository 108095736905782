<template>
    <div class="full-screen">
        <cart-list />
    </div>
</template>

<script>
import Button from '@/components/misc/ButtonComponent'
import router from '@/router'
import store from '@/store/index.js';
import { createNamespacedHelpers } from 'vuex';
import CartList from '@/components/cart/CartList.vue';
const { mapActions } = createNamespacedHelpers('cartModule');

export default{
  components: {
    'v-button': Button,
    CartList
  },
  methods:{

  }
}
</script>

<style scoped>
.background-color-change {
  background: white !important;
}
.full-screen{
  position: absolute;
  width:100vw;
  height:100vh;
  background-color:white;
  left:0;
  top:0;
}
</style>