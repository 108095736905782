<template>
    <div class="product-management-wrapper">
        <product-title-picker :product="product" />
        <div class="product-image" v-if="product.image">
            <img :src="product.image"/>
        </div>
        <product-description-picker :product="product" />
        <div class="price-picker-wrapper">
            <original-price-picker :product="product" />
            <price-picker :product="product" />
        </div>
        <quantity-picker :product="product" />
    </div>
</template>


<script>
import { ref, onMounted  } from 'vue';
import '@vuepic/vue-datepicker/dist/main.css'
import apiClient from "@/apiClient.js"
import constants from "@/apiConstants.js"
import OriginalPricePicker from "@/components/vendor/management/product/OriginalPricePicker.vue"
import PricePicker from "@/components/vendor/management/product/PricePicker.vue"
import Button from '@/components/misc/ButtonComponent'
import QuantityPicker from '@/components/vendor/management/product/QuantityPicker.vue';
import ProductTitlePicker from '@/components/vendor/management/product/ProductTitlePicker.vue';
import ProductDescriptionPicker from '@/components/vendor/management/product/ProductDescriptionPicker.vue';
export default {
    
    name:"product-management-modal",
    props:["product"],
    components:{
        "v-button": Button,
        QuantityPicker,
        ProductTitlePicker,
        OriginalPricePicker,
        ProductDescriptionPicker,
        PricePicker
    },
    data(){

    },
    methods:{

    }
}
</script>
<style scoped>
.product-management-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}
.price-picker-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
}

.product-management-wrapper .product-title{

}
.product-management-wrapper .product-image img{
    max-width:125px;
    height:auto;
}
</style>