<template>
  <div class="counter-flex">
    <div class="counter">
      <div class="btn" @click="deleteItem">-</div>
      <div class="count">{{getQuantity}}</div>
      <div class="btn" @click="addItem">+</div>
    </div>
  </div>
</template>


<script>
import store from '@/store/index.js';
import { createNamespacedHelpers } from 'vuex';
import { parse } from 'libphonenumber-js';
const { mapActions } = createNamespacedHelpers('cartModule');
export default {
  
  name: "add-to-cart",
  props: ["id","max_quantity"],
  methods: {
    ...mapActions(['addToCart', 'removeFromCart']),
    addItem() {
      if(parseInt(this.max_quantity) >= parseInt(store.getters["cartModule/getProductQuantity"](this.id))+1){
        this.addToCart({product_id: this.id, quantity: 1});
      }
    },
    deleteItem() {
      this.removeFromCart({product_id: this.id, quantity: 1});
    },
  },
  computed: {
    getQuantity(){
      var quantity = store.getters["cartModule/getProductQuantity"](this.id)
      return quantity;
    },
  }
};
</script>

<style scoped>
.counter-flex{
  display:flex;
  gap:1em;
  justify-content: space-evenly;
  align-items: center;
}
.price{
  font-weight:700;
  color:black;
  font-size:24px;
  text-align:center;
  flex:1 1 auto;
  max-width:150px;
}
.counter{
 flex: 1 1 auto;
 max-width:150px;
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding:0.25em;
 border:1px solid black;
 border-radius:36px;
}
.btn{
 width: 40px;
 height: 40px;
 border-radius: 50%;
 color:black;
 display: flex;
 justify-content: center;
 align-items: center;
 font-size: 20px;
 font-weight: 800;
 cursor: pointer;
}
.count{
 font-size: 20px;
 font-weight: 800;
 color: black;
}
</style>