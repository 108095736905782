<template>
  <auth-modal></auth-modal>
  <transition name="moveUp-absolute">
    <top-nav v-if="!$route.meta.hideNavbar" />
  </transition>
  <div class="app-container" >
    <router-view v-slot="{Component}">
      <transition name="moveUp">
        <component :is="Component" :key="$route.path"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import { defineComponent, ref, onBeforeMount } from 'vue'
import AuthModal from "./components/auth/AuthModal.vue";
import constants from "@/apiConstants.js"
export default defineComponent({
  
  components: {
    AuthModal,
  },
  setup() {
    onBeforeMount(() => {
      //if (window.location.protocol !== 'https:') window.location.href = window.location.href.replace('http:', 'https:');
      const stripeLoaded = ref(false)
      const stripePromise = loadStripe(constants.STRIPE_PUBLISHABLE_KEY)
      stripePromise.then(() => {
        stripeLoaded.value = true
      })
    })
  },
})
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
p,h1,h2,h3,h4,h5,h6{
    margin-bottom: 0px !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.dp__input{
  background: #000;
  padding: 1.5em 2em;
  color: #fff;
  font-weight: 800;
  border-radius: 36px;
  text-align: center;
}

.fields{
  display:flex;
  flex-direction: column;
  gap:1em;
}
.input{
  background-color:#F1F1F1;
  padding:1em 1.5em;
  border-radius:32px;
  font-size:18px; /*you just got pranked reeeee*/ 
  flex: 1 1 auto;
}
/* */

.error{
  color:#DE2828;
}
/* MODAL STYLING */
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  text-align: center;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  border-radius:32px;
  display: flex;
  max-height: 90%;
  overflow-y: auto;
}
.modal__title {
  font-size: 1.25rem;
  font-weight: 700;
  color:black;
}
.modal__content.flex{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:1em;
    overflow-y: auto;
}

.m-input-number .m-btn.--is-button{
    background-color:transparent !important;
    border:unset !important;
    color:black !important;
    font-size:32px !important;
    font-weight: 800;
}
.m-input-number .m-input-number__button__icon {
  height: 2rem !important;
  width: 2rem  !important;
  stroke-width: 5  !important;
}
.m-input-number .m-input-number__button__icon path{
  stroke-width: 5  !important;
}
.m-input-number .m-input-number__input{
  min-width: 60px !important;
  width:90px !important;
}
.m-input-number .m-input-wrapper.maz-border{
  border:unset !important;
  text-align: center !important;
  border-width:0px !important;
}
.m-input-number .m-input-wrapper-input input{
  text-align: center !important;
  font-size:32px !important;
  font-weight:800;
}

.slide-enter-active, .slide-leave-active{
  transition: opacity 1s, transform 1s;
}

.slide-enter-from, .slide-leave-to{
  opacity:0;
  transform:translateY(30%);
}

.moveUp-enter-active{
  animation: fadeIn 1s ease-in;
}
@keyframes fadeIn{
  0%{ opacity:0;}
  50%{ opacity:0;}
  100%{ opacity:1;}
}
.moveUp-leave-active{
  animation: moveUp 0.3s ease-in;
}
@keyframes moveUp{
  0%{opacity:1;}
  100%{opacity:0;}
}

.moveUp-absolute-enter-active{
  animation: fadeIn-absolute 1s ease-in;
}
@keyframes fadeIn-absolute{
  0%{ opacity:0; position: absolute;}
  30% { opacity:0;position: absolute;}
  50%{ opacity:0; position: relative;}
  100%{ opacity:1; position: relative; }
}
.moveUp-absolute-leave-active{
  animation: moveUp-absolute 0.3s ease-in;
}
@keyframes moveUp-absolute{
  0%{opacity:1; position: relative;}
  100%{opacity:0; position: relative;}
}

.module{
  border-radius:50px;
  background-color:white;
  color:black;
  padding:1em;
  transition:opacity 1s;
}
.module.black{
  background-color:black;
  color:white;
}
.module .title{
  font-weight:800;
  font-size:16px;
}

.tab-wrapper{
  display:flex;
  flex-direction: column;
  gap:1em;
}
.tab-wrapper .tabs{
  display: flex;
  flex-direction: row;
  gap:1em;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.tab-wrapper .tabs::-webkit-scrollbar {
  display: none;
}

.tab-wrapper .tabs .tab{
  padding:1em 2em;
  font-size:16px;
  font-weight: 800;
  background-color:#D9D9D9;
  color:white;
  cursor:pointer;
  transition:.3s;
}
.tab-wrapper .tabs .tab.active{
  background-color:black;
}
.tab-wrapper .tab-content{

}
.grabbable{
  cursor: move;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

#app {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height:100vh;
  background-color:#F5F5F5;
  padding:0px 1em 0px 1em;
  padding-bottom:128px;
}

.navbar {
  background-color:#F5F5F5;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
