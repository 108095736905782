export default () => ({
    loggedInUser: false,
    token: "",
    id: "",
    authModal: {
      visibility: false,
      callbackAction: {
        name: null,
        payload: null
      }
    },
  });