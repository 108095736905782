import { createStore } from 'vuex'
import vuejsStorage from 'vuejs-storage'
import { start } from '@popperjs/core'
import getters from './getters.js'
import mutations from './mutations.js';
import actions from './actions.js';
import state from './state.js'

export default({
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
})
