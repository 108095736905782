import constants from "@/apiConstants.js"
export default {
  
    async submitLogin({ commit }, payload) {
      const response = await fetch(constants.API_BASE +"authentication/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone_number: payload["phone_number"],
          email: payload["email"],
          password: payload["password"],
        }),
      })
      var data = await response.json();
      console.log(data)
        if(data.token){
          commit("UPDATE_USER_LOGIN_STATUS", true);
          commit("UPDATE_USER_TOKEN", data.token);
          commit("UPDATE_USER_ID", data.user.id);
          //TODO: Remove
          window.location.reload()
          return true;
        }else{
          commit("UPDATE_USER_LOGIN_STATUS", false);
          commit("UPDATE_USER_TOKEN", "");
          commit("UPDATE_USER_ID", "");
          return false;
        }
      
    },
    showAuthModal({ commit }, payload) {
      commit("UPDATE_AUTH_MODAL_VISIBILITY", {
        callbackAction: payload.callbackAction,
        visibility: true
      });
    },
    hideAuthModal({ commit }) {
      commit("UPDATE_AUTH_MODAL_VISIBILITY", {
        visibility: false,
        callbackAction: {
          name: null,
          payload: null
        }
      });
    },
    setToken({ commit },state, token){
      commit("UPDATE_USER_TOKEN", {
        token: token,
      });
    },
    logout({ commit }){
      commit("UPDATE_USER_LOGIN_STATUS", false);
      commit("UPDATE_USER_TOKEN", "");
      commit("UPDATE_USER_ID", "");
      document.location.href="/";
    }
  };