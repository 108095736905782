import Vue from "vue";
import Vuex from "vuex";
import vuejsStorage from 'vuejs-storage'
import authModule from "./authModule/index";
import cartModule from "./cartModule/index";



const store = new Vuex.Store({
  modules: {
    authModule,
    cartModule
  },
  plugins: [
    vuejsStorage({
      keys: ['cartModule'],
      namespace: 'cartModule',
      driver: vuejsStorage.drivers.localStorage
    }),
    vuejsStorage({
      keys: ["authModule"],
      namespace: 'authModule',
      driver: vuejsStorage.drivers.localStorage
    })
  ]
  
});
export default store;