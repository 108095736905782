<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a href="#" class="navbar-brand">
        <!-- Logo Image -->
        <img src="@/assets/logo.png" />
      </a>
      <div class="profile">
        <div v-if="isLoggedIn">
          <div @click="logout()">Logout</div>
        </div>
        <div v-else>
          <!-- <font-awesome-icon icon="fa-solid fa-circle-user" size="2x" :style="{ color: 'black' }" /> -->
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import store from "@/store/index.js";
import Button from "@/components/misc/ButtonComponent";

export default {
  name: "TopNav",
  components: {
    "v-button": Button,
  },
  methods: {
    logout() {
      store.dispatch("authModule/logout");
    },
  },
  data() {
    return {
      isLoggedIn: false,
    };
  },
  mounted() {
    this.isLoggedIn = store.getters["authModule/isLoggedIn"];
  },
};
</script>

<style scoped>
.navbar-brand img {
  width: 20vw;
  max-width: 125px;
  height: auto;
}
</style>
