<template>
    <div class="product-display" v-if="product">
        <Transition>
        <div class="cover" v-if="product.quantity < 1">
            <p>Sold out</p>
        </div>
        </Transition>
        <h1 class="product-title">{{product.title}}</h1>
        <div class="product-image" v-if="product.image">
            <img :src="product.image">
        </div>
        <div class="product-price">
            <p class="original-price">${{product.original_price}}</p>
            <p class="current-price">${{product.price}}</p>
        </div>
        <add-to-cart :id=product.id :max_quantity="product.quantity"/>
    </div>
</template>

<script>
import axios from "axios"
import addToCart from "@/components/cart/AddToCartButton"
import store from '@/store/index.js';
import constants from "@/apiConstants.js"
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('cartModule');

export default {
  name: 'ProductDisplayComponent',
  props:{
    id: {
        type: Number,
        default: null,
    },
    given_product: {
        type: Object,
        default: null,
    }
  },
  components: {
    "add-to-cart": addToCart,
  },
  data(){
    return{
        product: null,
        location: null,
    }
  },
  methods: {
    ...mapActions(['addToCart', 'removeFromCart']),
    async fetchProduct(){
        if(this.id){
            try{
                const url = constants.API_BASE + constants.product.BASE + this.id
                const response = await axios.get(url);
                const results = response.data
                this.product = results;
                this.fetchLocation(this.product.related_location)
            }catch(err){
                if (err.response) {
                    // client received an error response (5xx, 4xx)
                    console.log("Server Error:", err)
                } else if (err.request) {
                    // client never received a response, or request never left
                    console.log("Network Error:", err)
                } else {
                    console.log("Client Error:", err)
                }
            };
        }else{
            this.product = this.given_product;
        }
    },
    async fetchLocation(location_id){
        try{
            const url = constants.API_BASE + constants.location.BASE + location_id
            const response = await axios.get(url);
            const results = response.data
            this.location = results;
            console.log(results);
        }catch(err){
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log("Server Error:", err)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log("Network Error:", err)
            } else {
                console.log("Client Error:", err)
            }
        };
    }
  },
  mounted(){
    this.fetchProduct();
    if(this.given_product.quantity < 1){
        var quantityToRemove = store.getters["cartModule/getProductQuantity"](this.given_product.id)
        this.removeFromCart({quantity: quantityToRemove,product_id:this.given_product.id})
    }

  }
}
</script>

<style scoped>
.cover{
    background-color:rgba(255,255,255,0.8);
    z-index:5;
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:1em;
}
.cover p{
    font-weight: 800;
    font-size:24px;
    color: #1A1A1A;
}

.product-display{
    position: relative;
    display:flex;
    flex-direction: column;
    align-content: center;
    flex:1 1 auto;
    justify-content: center;
    align-items: center;
    gap:1em;
}
.product-display .product-title{
    color:black;
    font-size:calc(18px);
    font-weight:700;
}

.product-display .product-price{
    display:flex;
    flex-direction: row;
    gap:1em;
}

.product-display .product-price .original-price{
    color:#D9D9D9;
    text-decoration: line-through;
}

.product-display .product-price .current-price{
    color:black;
}
.product-display .product-price .current-price, .product-display .product-price .original-price{
    font-size: 1em;
    font-weight:800;
}


.product-display .product-description{
    color:black;
    font-size:calc(10px + 1vw);
    font-weight:700;
}

.product-display .product-image{
    flex:1 1 auto;
}
.product-display .product-image img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;
  max-width:200px;

}
</style>