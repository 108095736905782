<template>
<div class="description-container">
  <MazInput v-model="description" style="border:unset !important;" />
  <v-button @click="updatedescription()" style="max-width: 200px; width:200px; min-width:unset; font-size:16px;">Update description</v-button>
</div>
</template>


<script>
import { ref, onMounted  } from 'vue';
import '@vuepic/vue-datepicker/dist/main.css'
import apiClient from "@/apiClient.js" 
import {useToast} from 'vue-toast-notification';
import constants from "@/apiConstants.js"
import Button from '@/components/misc/ButtonComponent'

export default {
    
    name:"product-description-picker",
    props:["product"],
    components:{
        "v-button": Button
    },
    data(){
        return{
            description: this.product.description,
        }
    },
    methods:{
        async changedescription(payload){
            const url = constants.API_BASE + constants.product.BASE + this.product.id + constants.product.UPDATE_DESCRIPTION
            apiClient.post(url, payload).then(response=>{
                const $toast = useToast();
                $toast.success('description successfully updated.', {
                // optional options Object
                })
            })
        },
        updatedescription(){
            this.changedescription({description:this.description})
            this.product.description = this.description;
        }
    }
}
</script>
<style scoped>
.description-container{
    display:flex;
    gap:1em;
}
</style>