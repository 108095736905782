<template>
  <div class="order-wrapper">
    <div class="order-display">
      <img src="@/assets/logo.png" />
      <p class="title">Your order is confirmed!</p>
      <div class="details">
        <order-display-component
          v-for="item in order_items"
          :key="item.related_product"
          :id="item.related_product"
          :quantity="item.quantity"
        />
      </div>
      <div class="order-amount">
        <p class="subtitle">Sub total: {{ order.sub_total }}</p>
        <p class="subtitle">Taxes & Fees: {{ order.tax }}</p>
        <p class="title">Total: {{ order.total }}</p>
      </div>
      <br />
      <div class="order-amount">
        <p class="subtitle">Questions? +1 (385) 317-2649</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VendorDisplayPage from "@/components/vendor/VendorDisplayPage";
import OrderDisplayComponent from "@/components/product/OrderDisplayComponent";
import constants from "@/apiConstants.js";

export default {
  name: "OrderDetailView",
  params: ["uuid"],
  components: {
    VendorDisplayPage,
    "order-display-component": OrderDisplayComponent,
  },
  data() {
    return {
      order: null,
      order_items: null,
      uuid: this.$route.params.uuid,
    };
  },
  methods: {
    async fetchOrder() {
      try {
        const url = constants.API_BASE + constants.order.BASE + this.uuid;
        const response = await axios.get(url);
        const results = response.data;
        this.order = results;
        this.order_items = results["items"];
        var vendorLocation = 0;
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("Server Error:", err);
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("Network Error:", err);
        } else {
          console.log("Client Error:", err);
        }
      }
    },
  },
  mounted() {
    this.fetchOrder();
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0px;
}
.order-wrapper {
  padding: 2em;
  text-align: left;
}
.order-display {
  background: #3bbbd7;
  border-radius: 32px;
  color: white;
  padding: 2em;
  display: flex;
  gap: 2em;
  flex-direction: column;
}
.details {
  display: flex;
  flex-direction: column;
  gap: 5em;
}

.order-display img {
  max-width: 100px;
}

.title {
  font-size: 24px;
  font-weight: 800;
}
.subtitle {
  color: #dfdfdf;
  font-size: 18px;
}
.subtitle.white {
  color: white;
  font-size: 18px;
}
</style>
