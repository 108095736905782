<template>
    <img @click="redirect_to_homepage()" src="@/assets/icons/back_button.svg" class="back_button">
</template>
<script>
import router from "@/router"
export default {
  name: 'back-button',
  methods:{
    redirect_to_homepage(){
        router.push({ name: 'home'})
    }
  },
}
</script>
<style scoped>
.back_button{
    width:30px;
    height:30px;
    position: absolute;
    top:20px;
    left:20px;
}
</style>