<template>
    <div class="success">
        <h1 style="margin-top:50px;">Payment Failed</h1> 
        <p>Something went wrong with the payment!</p>
        <a href='https://shop.gobiite.com/preorder'>Click here to try again.</a>
    </div>
</template>

<script>

</script>

<style scoped>
.success{
  position:absolute;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
h1 {
  color: #C98BDB;
 font-weight: 800;
  font-size: 40px;
  margin-bottom: 10px;
}
p,a {
  color: #FFF;
  font-size:20px;
  margin: 0;
}
i {
color: #9ABC66;
font-size: 50px;
line-height: 100px;
margin-left:-15px;
}
</style>