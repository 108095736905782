<template>
    <div class="vendor-display" v-if="vendor" style="position:relative;">
        <cart-icon/>
        <div class="vendor-header module">
            <img class="vendor-image" v-lazy="vendor.header"/>
            <back-button/>
        </div>
        <div class="vendor-content">
            <div></div>
            <div v-if="selected_location">
                <div class="pickup-information">
                    <Transition>
                    <pickup-delivery-location @update="selectLocation" v-if="selected_location" :locations="vendor.locations" :selected_location="selected_location" />
                    </Transition>
                </div>
            </div>
            <product-display-list v-if="selected_location" :given_products="selected_location.products" />
        </div>
    </div>
</template>

<script>
import axios from "axios"
import QuantityBoxComponent from '@/components/vendor/QuantityBoxComponent'
import VendorLocationComponent from '@/components/vendor/VendorLocationComponent.vue'
import Button from '@/components/misc/ButtonComponent'
import CheckoutModal from '@/components/checkout/CheckoutModal.vue'
import CartIcon from '@/components/cart/CartIcon.vue'
import PickupDeliveryLocation from '@/components/location/PickupDeliveryModule.vue'
import BackButton from '@/components/misc/BackButton.vue'
import router from "@/router"
import constants from "@/apiConstants.js"
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import QuantityComponent from '@/components/product/QuantityComponent.vue'
import ProductDisplayList from '@/components/product/ProductDisplayList.vue'

export default {
  name: 'vendor-display-page',
  props:['id'],
  components: {
    QuantityBoxComponent,
    VendorLocationComponent,
    VueFinalModal,
    PickupDeliveryLocation,
    ModalsContainer,
    CartIcon,
    CheckoutModal,
    BackButton,
    "v-button": Button,
    QuantityComponent,
    ProductDisplayList
  },
  data(){
    return{
       vendor: null,
       location_modal_show: false,
       reserve_modal_show: false,
       payment_modal_show: false,
       quantity_modal_show:false,
       selected_location: {
        type:Object,
        default:null,
       },
       opening_time: null,
       closing_time: null,
       display_opening_time:null,
       display_closing_time:null,
       quantity : 1,

    }
  },
  methods: {
    async fetchVendor(){
        try{
            const url = constants.API_BASE + constants.vendor.BASE + this.id
            const response = await axios.get(url);
            const results = response.data
            this.vendor = results;
            var vendorLocation = 0
            if(this.$route.query.location){
                vendorLocation = parseInt(this.$route.query.location)
                this.selected_location = this.vendor.locations.find(item => item.id === vendorLocation);
            }else{
                this.selected_location = this.vendor.locations[vendorLocation]
                for(let i = 0; i < this.vendor.locations.length; i++){
                    if(parseInt(this.vendor.locations[i].total_quantity) > 0){
                        this.selected_location = this.vendor.locations[i];
                        break;
                    }
                }
            }
            this.updateTime();
        }catch(err){
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log("Server Error:", err)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log("Network Error:", err)
            } else {
                console.log("Client Error:", err)
            }
        };
    },
    updateTime(){
        var d=new Date();
        var locationTimeData;
        var objDict = this.selected_location.pickup_times
        Object.keys(objDict).forEach(x => locationTimeData = objDict[x].day === d.getDay().toString() ? objDict[x]: locationTimeData);
        if(locationTimeData){
            this.opening_time = locationTimeData.opening_time
            this.closing_time = locationTimeData.closing_time
            this.display_opening_time = moment.utc(this.opening_time).local().format('h:mm A')
            this.display_closing_time = moment.utc(this.closing_time).local().format('h:mm A')
        }else{
            //If the store doesn't have any time set for this day, this is the message that will be displayed
            this.display_opening_time = "Contact store"
            this.display_closing_time = ""
        }
    },
    selectLocation(location_id){
        console.log("Location_id", location_id)
        this.selected_location = this.vendor.locations.find(item => item.id === location_id);
        router.push({ query: {"location":location_id} }).catch(failure => false);
        this.updateTime();
    },
  },
  mounted(){
    this.fetchVendor();
  },
}
</script>

<style scoped>
p,h1,h2,h3,h4,h5,h6{
    margin-bottom:0px;
}
.vendor-display{
    padding:1em 0em;
    width:100%;
    height:100%;
    position: relative;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vendor-display .vendor-header{
    max-width: 400px;
    width:100%;
    height:200px;
    position: relative;
    overflow:hidden;
}
.vendor-display .vendor-header img.vendor-image{
  width:100%;
  height:100%;
  object-fit: contain;
  left:0;
  position: absolute;
  top:0;
}

.vendor-location-wrapper{
    padding:0em;
}

.vendor-content{
    text-align: left;
    display: flex;
    flex-direction: column;
    gap:1.5em;
    max-width:400px;
    width:100%;
}

.vendor-header .quantity{
  top:unset;
  right:unset;
  left:unset;
  bottom:20px;
  right:20px;
}
.title{
    font-size:22px;
 font-weight: 800;
    color:black;
    margin-bottom:0px;
}
.subtitle{
    color:#585858;
    font-size:16px;
 font-weight: 800;
    margin-bottom:0px;
}
.subtitle.black{
    color:black;
}

.vendor-display .product-information, .pickup-information{
    display:flex;
    justify-content:space-between;
}
.pickup-information{
    align-items: center;
}

.vendor-display .product-information .product-pricing{
    display:flex;
    flex-direction: column;
}

.vendor-display .product-information .product-pricing .original-price{
    color:#585858;
    text-decoration: line-through;
    font-size: 1.35rem;
}

.vendor-display .product-information .product-pricing .current-price{
    font-size:1.75rem;
    color:black;
}

.original-price, .current-price{
    width:100px;
    text-align: center;
 font-weight: 800;
}

.pickup-information .day{
  padding: 0.25em 0.75em;
  flex-grow: 0;
  font-size:0.95rem;
  margin-left:2em;
  color:#C98BDB;
  font-weight: 700;
  background-color:#E9CFF0;
  border-radius:32px;
}

.pickup-information .pickup-time{
    font-size:18px;
 font-weight: 800;
}


@keyframes translate-y-in{
  0%{transform: translateY(-100%); opacity:0;}
  100%{transform: translateY(0%); opacity:1;}
}

@keyframes translate-y-out{
  0%{transform: translateY(0%); opacity:1;}
  100%{transform: translateY(-100%); opacity:0;}
}
.translate-y-full{
  transition: translate-y-in 0.2s;
}
.translate-y-0 {
  transition: translate-y-out 0.2s;
}

.quantity-information{
    display: flex;
    flex-direction: column;
    gap: 2em;
    padding:0em 2em
}
.quantity-information .price{
    display: flex;
    justify-content:space-between;
}
.quantity-information .price p {
    color:#585858;
    font-size:18px;
    font-weight:700;
}
.quantity-information .price.total p {
    color:black;
    font-size:24px;
    font-weight:800;
    margin-top:0.25em;
}
.quantity-information .subtitle{
    font-size:16px;
    color:#585858;
}
</style>
