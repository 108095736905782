<template>
<div class="location-manager-wrapper" v-if="renderComponent">
    <div v-if="total_sales" class="module orders-wrapper">
        <span class="title">This Months Sales</span>
        <h1>${{total_sales}}</h1>
    </div>
    <div v-if="location" class="module products-wrapper">
        <span class="title">Products</span>
        <div class="products grabbable">
            <product-display-box v-for="product in location.products" v-bind:product="product"/>
        </div>
    </div>
    <div v-if="orders" class="module orders-wrapper">
        <span class="title">Orders</span>
        <div class="orders grabbable">
            <order-display-box v-for="order in orders" v-bind:order="order"/>
        </div>
    </div>
    <div v-if="location" class="module time-wrapper">
        <span class="title">Pick-up time</span>
        <span class="sub-title">This month</span>
        <div class="tab-wrapper">
            <div class="tabs grabbable">
                <div class="tab module" @click="changeDay(0)" :class="{ active: day==0 }">Sunday</div>
                <div class="tab module" @click="changeDay(1)" :class="{ active: day==1 }">Monday</div>
                <div class="tab module" @click="changeDay(2)" :class="{ active: day==2 }">Tuesday</div>
                <div class="tab module" @click="changeDay(3)" :class="{ active: day==3 }">Wednesday</div>
                <div class="tab module" @click="changeDay(4)" :class="{ active: day==4 }">Thursday</div>
                <div class="tab module" @click="changeDay(5)" :class="{ active: day==5 }">Friday</div>
                <div class="tab module" @click="changeDay(6)" :class="{ active: day==6 }">Saturday</div>
            </div>
            <Transition>
            <div class="tab-content">
                <time-picker :location="location" :day="day_computed"/>
            </div>
            </Transition>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios"
import timePicker from "@/components/vendor/management/TimePicker"
import ProductDisplayBox from "@/components/vendor/management/product/ProductDisplayBox.vue"
import OrderDisplayBox from "@/components/vendor/management/orders/OrderDisplayBox.vue"
import constants from "@/apiConstants.js"
import apiClient from "@/apiClient.js"
import { ref, watchEffect, computed } from 'vue'
import { createNamespacedHelpers } from 'vuex';

export default {
  name: 'vendor-location-management',
  props:["location_id"],
  components: {
    timePicker,
    ProductDisplayBox,
    OrderDisplayBox,
  },
  setup() {
    var today = new Date();
    let day = ref(today.getDay())
    let total_sales = ref(null);
    const renderComponent = ref(true)
    const location = ref(null)
    const orders = ref(null)
    
    let day_computed = computed(() => {
        return day.value
    })

    function changeDay(change_to_day) {
        day.value = change_to_day;
        console.log(day.value)
    }
    // expose to template and other options API hooks
    return {
      day,
      renderComponent,
      location,
      orders,
      total_sales,
      day_computed,
      changeDay
    }
  },
  methods: {
    async fetchLocation(){
        try{
            const url = constants.API_BASE + constants.location.BASE + this.location_id
            const response = await axios.get(url);
            const results = response.data
            this.location = results;
            this.fetchOrders();
            this.fetchTotalSales();
        }catch(err){
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log("Server Error:", err)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log("Network Error:", err)
            } else {
                console.log("Client Error:", err)
            }
        };
    },
    async fetchOrders(){
        try{
            const url = constants.API_BASE + constants.location.BASE + this.location_id + constants.location.GET_ORDERS
            apiClient.get(url).then(response=>{
                console.log(response.data)
                this.orders = response.data;
            })
        }catch(err){
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log("Server Error:", err)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log("Network Error:", err)
            } else {
                console.log("Client Error:", err)
            }
        };
    },
    async fetchTotalSales(){
        try{
            const url = constants.API_BASE + constants.location.BASE + this.location_id + constants.location.GET_TOTAL_SALES
            apiClient.get(url).then(response=>{
                console.log(response.data)
                this.total_sales = response.data.total;
            })
        }catch(err){
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log("Server Error:", err)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log("Network Error:", err)
            } else {
                console.log("Client Error:", err)
            }
        };
    },
    forceRerender() {
        // Removing my-component from the DOM
        this.renderComponent = false;

        this.$nextTick(() => {
            // Adding the component back in
            this.renderComponent = true;
        });
    },

  },
  watch: {
    location_id: async function (val) {
        await this.fetchLocation();
        this.forceRerender();
    }
  },
  mounted(){
    this.fetchLocation();
  }
}
</script>
<style scoped>
.location-manager-wrapper{
    display: flex;
    flex-direction: column;
    gap:1em;
}

.orders-wrapper, .products-wrapper, .time-wrapper{
    display: flex;
    gap:1em;
    flex-direction: column;
    text-align: center;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.products::-webkit-scrollbar, .orders::-webkit-scrollbar {
  display: none;
}

.products, .orders{
    display:flex;
    flex-direction: row;
    overflow:auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.orders{
    gap:1em;
}
.products{
    gap:0.5em;
}
.title{
    font-weight: 800;
    font-size:16px;
}
.sub-title{
    font-weight: 800;
    font-size:14px;
}
</style>