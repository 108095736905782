export default {
    UPDATE_USER_LOGIN_STATUS(state, status) {
      state.loggedInUser = status;
    },
    UPDATE_AUTH_MODAL_VISIBILITY(state, authModal) {
      state.authModal.visibility = authModal.visibility;
      const { name, payload } = authModal.callbackAction;
      state.authModal.callbackAction = { name, payload };
    },
    UPDATE_USER_TOKEN(state, token){
      state.token = token;
    },
    UPDATE_USER_ID(state, id){
      state.id = id;
    },
    CLOSE_AUTH_MODAL(state){
      state.authModal.visibility = false;
    }
  };