<template>
<div class="price-container">
  <MazInputPrice
    v-model="price"
    label="Sale Price"
    currency="USD"
    locale="en-US"
    :min="1"
    :max="1000"
  />
  <v-button @click="updatePrice()" style="max-width: 200px; width:200px; min-width:unset; font-size:16px;">Update Price</v-button>
</div>
</template>


<script>
import { ref, onMounted  } from 'vue';
import '@vuepic/vue-datepicker/dist/main.css'
import {useToast} from 'vue-toast-notification';
import apiClient from "@/apiClient.js" 
import Button from '@/components/misc/ButtonComponent'
import constants from "@/apiConstants.js"
export default {
    
    name:"price-picker",
    props:["product"],
    components:{
        "v-button": Button
    },
    data(){
        return{
            price: this.product.price,
        }
    },
    //"Authentication": "Token " + token 
    methods:{
        async changePrice(payload){
            const url = constants.API_BASE + constants.product.BASE + this.product.id + constants.product.UPDATE_PRICE
            apiClient.post(url, payload).then(response=>{
                const $toast = useToast();
                $toast.success('Time successfully updated.', {
                // optional options Object
                })
            })
        },
        updatePrice(event){
            this.changePrice({price:this.price})
        }
    }
}
</script>
<style scoped>
.price-container{
    display:flex;
    gap:1em;
}
</style>