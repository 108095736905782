<template>
    <div>
        <div class="product-display" v-if="product">
            <div class="quantity-display">
                <span>{{this.quantity}}x</span>
            </div>
            <div class="product-information">
                <div class="product-title">{{product.title}}</div>
                <p>${{product.price}}</p>
            </div>
        </div>
        <div class="pickup" v-if="location">
            <span class="title">Pick-up:</span>
            <div class="subtitle">
                {{location.address}}
            </div>
            <div class="subtitle white">
                <p class="pickup-time white">{{display_opening_time}} - {{display_closing_time}} Today</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import constants from "@/apiConstants.js"

export default {
  name: 'order-display-component',
  props:['id','quantity'],
  data(){
    return{
        product: null,
        location: null,
        opening_time: null,
        closing_time: null,
        display_opening_time:null,
        display_closing_time:null,
    }
  },
  methods: {
    async fetchProduct(){
        try{
            const url = constants.API_BASE + constants.product.BASE + this.id
            const response = await axios.get(url);
            const results = response.data
            this.product = results;
            this.fetchLocation(this.product.related_location)
        }catch(err){
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log("Server Error:", err)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log("Network Error:", err)
            } else {
                console.log("Client Error:", err)
            }
        };
    },
    updateTime(){
        var d=new Date();
        var locationTimeData;
        var objDict = this.location.pickup_times
        Object.keys(objDict).forEach(x => locationTimeData = objDict[x].day === d.getDay().toString() ? objDict[x]: locationTimeData);
        if(locationTimeData){
            this.opening_time = locationTimeData.opening_time
            this.closing_time = locationTimeData.closing_time
            this.display_opening_time = moment.utc(this.opening_time).local().format('h:mm A')
            this.display_closing_time = moment.utc(this.closing_time).local().format('h:mm A')
        }else{
            //If the store doesn't have any time set for this day, this is the message that will be displayed
            this.display_opening_time = "Contact store"
            this.display_closing_time = ""
        }
    },
    async fetchLocation(location_id){
        try{
            const url = constants.API_BASE + constants.location.BASE + location_id
            const response = await axios.get(url);
            const results = response.data
            this.location = results;
            this.updateTime()
        }catch(err){
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log("Server Error:", err)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log("Network Error:", err)
            } else {
                console.log("Client Error:", err)
            }
        };
    }
  },
  mounted(){
    this.fetchProduct();
  }
}
</script>

<style scoped>
p{
    margin-bottom:0px !important;
}
.title{
  font-size:24px;
  font-weight:800;
}
.subtitle.white{
  color:white;
  font-size:18px;
}
.pickup-time {
    font-weight:800;
}
.subtitle{
  color:#DFDFDF;
  font-size:18px;
}
.product-display{
    display:flex;
    flex-direction: row;
    align-items: center;
    flex:1 1 auto;
    justify-content: center;
    margin-bottom:50px;
}
.product-display .product-image{
    flex:1 1 auto;
}
.product-display .quantity-display{
    display:flex;
    flex-direction: row;
    align-items: end;
    flex:1 1 auto;
    gap:10px;
}
.product-display .product-information{
    display:flex;
    flex-direction: row;
    align-items: center;
    flex:1 1 auto;
    gap:10px;
}
.product-display .product-title{
    font-size:16px;
}
</style>