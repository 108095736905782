<template>
  <div class="vendor-wrapper" v-if="vendors">
      <cart-icon/>
      <loader-component v-if="!vendors"></loader-component>
      <user-location-modal @update="getCloseLocations" />
      <Transition>
      <div v-if="locations" class="location-wrapper-content">
          <h1 class="title">Near Me</h1>
          <div class="error" v-if="error_message">Sorry, currently there are no locations near your zip code.<br>Want to see your favorite bakeries, and resturants on our platform?<br><a href="https://www.instagram.com/p/CkQ5m_uMBis/?hl=en">Tag them on our instagram post here</a></div>
          <vendor-display-box v-for="location in sortedLocations" v-bind:id="location.related_vendor" v-bind:slug="location.vendor_slug" v-bind:image_src="location.vendor_picture" v-bind:quantity="location.total_quantity" :location_id="location.id" :quantity_visible="false"></vendor-display-box>
      </div>
      </Transition>
      <div class="vendor-wrapper-content">
        <h1 class="title">All Vendors</h1>
        <vendor-display-box v-for="vendor in sortedVendors" v-bind:id="vendor.id" v-bind:slug="vendor.slug" v-bind:image_src="vendor.header" v-bind:quantity="vendor.total_quantity" :quantity_visible="false"></vendor-display-box>
      </div>
  </div>
</template>

<script>
import axios from "axios"
import VendorDisplayBox from "@/components/vendor/VendorDisplayBox"
import LoaderComponent from '@/components/misc/LoaderComponent.vue'
import UserLocationModal from '../components/location/UserLocationModal.vue'
import CartIcon from "@/components/cart/CartIcon.vue"
import constants from '@/apiConstants'
export default {
  name: 'HomeView',
  props:['id'],
  components: {
    VendorDisplayBox,
    LoaderComponent,
    CartIcon,
    UserLocationModal,
  },
  data(){
    return{
        vendors: null,
        locations: null,
        error_message: false,
    }
  },
  methods: {
    async fetchVendors(){
        try{
            const url = constants.API_BASE + constants.vendor.BASE
            const response = await axios.get(url);
            const results = response.data
            this.vendors = results.results;
            console.log(results.results);
        }catch(err){
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log("Server Error:", err)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log("Network Error:", err)
            } else {
                console.log("Client Error:", err)
            }
        };
    },
    async getCloseLocations(long, lat){
      try{
          this.error_message = false;
            const url = constants.API_BASE + "location/get_within_range/?latitude=" + lat + "&longitude=" + long + "&radius=15"
            const response = await axios.get(url);
            const results = response.data
            this.locations = results;
            console.log(results.results);
            if(this.locations.length < 1){
              this.error_message = true;
            }
        }catch(err){
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log("Server Error:", err)
            } else if (err.request) {
                // client never received a response, or request never left
                console.log("Network Error:", err)
            } else {
                console.log("Client Error:", err)
            }
        };
    }

  },
  mounted(){
    this.fetchVendors();
  },
    computed : {
      sortedVendors() {
          return this.vendors.sort((a, b) => { return b.total_quantity - a.total_quantity;});
      },
      sortedLocations() {
          return this.locations.sort((a, b) => { return b.total_quantity - a.total_quantity;});
      }
    }
}
</script>

<style scoped>
.vendor-wrapper{
  display:flex;
  justify-content: center;
  align-items:center;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  gap:1em;
}
.location-wrapper-content .title, .vendor-wrapper-content .title{
  font-size:20px;
  color:black;
  font-weight: 800;
  text-align: start;
  width:100%;
}
.location-wrapper-content .error{
  float:left;
  font-size:16px;
  color:#DE2828;
  font-weight: 800;
  width:100%;
}
.vendor-wrapper-content, .location-wrapper-content{
  display:flex;
  justify-content: center;
  align-items:center;
  flex-wrap: wrap;
  flex-direction: row;
  gap:1em;
  width:100%;
  max-width:400px;
  flex: 1 1 auto;
}
</style>