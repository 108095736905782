<template>
    <div class="quantity" v-if="visible">
        <span v-if="quantity > 0">{{this.quantity}} Left</span>
        <span v-else>SOLD OUT</span>
    </div>
</template>


<script>
import axios from "axios"

export default {
  name: 'quantity-box',
  props:{
    quantity: Number,
    visible:{
      type:Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
.quantity{
  position: absolute;
  top:20px;
  left:20px;
  padding:0.5em 0.75em 0.5em 0.75em;
  font-size:0.75em;
  color:white;
  font-weight: 700;
  background-color:black;
  border-radius:32px;
}
</style>