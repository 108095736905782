export default{
    addToCart({ commit, dispatch, rootState }, payload) {
        // if (!rootState.authModule.loggedInUser) { // if user not logged in
        //   dispatch( // show auth-modal with callbackAction (name, payload)
        //     "authModule/showAuthModal",
        //     {
        //       callbackAction: {
        //         name: "cartModule/addToCart",
        //         payload
        //       }
        //     },
        //     { root: true }
        //   );
        //   return Promise.resolve();
        // }
        return Promise.resolve(payload).then(res => { // fake API call
            commit("ADD_TO_CART", payload);
            return res;
        });
      },
      removeFromCart({ commit, dispatch, rootState }, payload){
        return Promise.resolve(payload).then(res => { // fake API call
            commit("REMOVE_FROM_CART", payload);
            return res;
        });
      },
      cleanCart({ commit, dispatch, rootState }, payload){
        return Promise.resolve(payload).then(res => { // fake API call
            commit("CLEAN_CART", payload);
            return res;
        });
      }
    }