export default{
    
ADD_TO_CART(state, payload) {
    if(state.cart[payload.product_id]){
        state.cart[payload.product_id] += payload.quantity;
     }else{
        state.cart[payload.product_id] = payload.quantity;
     }
  },
  REMOVE_FROM_CART(state, payload){
    var total_quantity = state.cart[payload.product_id]
    if(total_quantity-payload.quantity > 0){
        state.cart[payload.product_id] -= payload.quantity
    }else{
      delete state.cart[payload.product_id]
    }
  },
  CLEAN_CART(state, payload){
    delete state.cart;
  }
}