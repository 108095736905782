import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import VendorDisplayView from "@/views/vendor/VendorDisplayView.vue"
import VendorManagementPortal from "@/views/vendor/management/VendorManagementPortal.vue"
import PaymentSuccess from '@/views/checkout/PaymentSuccessView'
import OrderDetailView from '@/views/checkout/OrderDetailView'
import CartDetailView from '@/views/cart/CartDetailView'
import PaymentFailure from '@/views/checkout/PaymentFailureView'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/vendor/:vendor_id',
    name: 'vendor_display',
    component: VendorDisplayView,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/cart/',
    name: 'cart_display',
    component: CartDetailView,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/management/vendor/',
    name: 'vendor_management_portal',
    component: VendorManagementPortal,
  },
  {
    path: '/order/:uuid',
    name: 'order',
    component: OrderDetailView
  },
  {
    path: '/success/',
    name: 'success',
    component: PaymentSuccess
  },
  {
    path: '/failure/',
    name: 'failure',
    component: PaymentFailure
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
      
    } else {
      return { top: 0 }
    }
  }
})

export default router
