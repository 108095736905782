//api-client.js
import axios from 'axios'
import store from '@/store'

const apiClient = axios.create({
    withCredentials: false, // This is the default
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

apiClient.interceptors.request.use(function (config) {
    // Do something before request is sent
    let authKey = store.getters["authModule/getToken"]
    config.headers["Authorization"] = "Token " + authKey;
    return config;
});

apiClient.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          store.dispatch("authModule/logout")
          window.location.reload()
        }
      }
      return Promise.reject(err);
    }
  );
export default apiClient;