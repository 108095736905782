<template>
    <div class="product-list-display module" v-if="computedProducts">
        <product-display-component v-for="item in computedProducts" :key="item" :given_product="item" :quantity="item.quantity"/>
    </div>
</template>

<script>
import axios from "axios"
import addToCart from "@/components/cart/AddToCartButton"
import ProductDisplayComponent from "@/components/product/ProductDisplayComponent.vue"

export default {
  name: 'product-display-list',
  props:{
    location_id: {
        type: Number,
        default: null,
    },
    given_products: {
        type: Object,
        default: null,
    }
  },
  components: {
    "add-to-cart": addToCart,
    ProductDisplayComponent,
  },
  data(){
    return{
        products: null,
        location: null,
    }
  },
  methods: {
    async fetchLocation(){
        if(this.location_id){
            try{
                const url = constants.API_BASE + constants.location.BASE + location_id
                const response = await axios.get(url);
                const results = response.data
                this.location = results;
                console.log(results);
            }catch(err){
                if (err.response) {
                    // client received an error response (5xx, 4xx)
                    console.log("Server Error:", err)
                } else if (err.request) {
                    // client never received a response, or request never left
                    console.log("Network Error:", err)
                } else {
                    console.log("Client Error:", err)
                }
            };
        }else{
            this.products = this.given_products;
        }
    }
  },
  mounted(){
    this.fetchLocation();
    console.log(this.products)
  },
  computed:{
    computedProducts: {
    get(){
      return this.given_products
    },
  }
  }
}
</script>

<style scoped>
.product-list-display{
    display:flex;
    flex-direction: column;
    align-content: center;
    flex:1 1 auto;
    padding:2em;
    text-align: center;
    gap:2em;
    justify-content: center;
    align-items: center;
}
</style>