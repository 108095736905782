<template>
    <div class="time-picker-wrapper">
        <Datepicker v-model="opening_time" timePicker @update:modelValue="handleOpeningTime" :clearable="false" dark><template #input-icon></template></Datepicker>
        <Datepicker v-model="closing_time" timePicker @update:modelValue="handleClosingTime" :clearable="false" dark><template #input-icon></template></Datepicker>
        <!-- <MazCheckbox  :color="color" :name="black" :key="closed" v-model="inputValue">Closed?</MazCheckbox> -->
    </div>
</template>

<script>
import { ref, onMounted  } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {useToast} from 'vue-toast-notification';
import apiClient from "@/apiClient.js" 
import constants from "@/apiConstants.js"
var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default {
    
    name:"time-picker",
    props:["location","day"],
    components:{
        Datepicker
    },
    setup() {
        const opening_time = ref({
            hours: new Date().getHours(),
            minutes: new Date().getMinutes()
        });
        const closing_time = ref({
            hours: new Date().getHours(),
            minutes: new Date().getMinutes()
        });
        const week_of_day = ""
        const inputValue = ref(false)
        return {
            opening_time,
            closing_time,
            week_of_day,
            inputValue
        }
    },

    //"Authentication": "Token " + token 
    methods:{
        async changeTime(payload){
            
            
            const url = constants.API_BASE + constants.location.BASE + this.location.id + constants.location.UPDATE_TIMES
            apiClient.post(url, payload).then(response=>{
                const $toast = useToast();
                $toast.success('Time successfully updated.', {
                // optional options Object
                })
            })


        },
        async changeClosed(closed){
            
        },
        handleOpeningTime(){
            //Get the hours and minutes
            const today = new Date();
            //Convert to datetime to push to database
            const modifiedDate = new Date(today.setHours(this.opening_time.hours, this.opening_time.minutes));
            //Convert to UTC
            const UTCDate = moment.utc(modifiedDate).format()
            var body_content = {opening_time: UTCDate, day:this.day}
            this.changeTime(body_content)
            
        },
        handleClosingTime(){
            //Get the hours and minutes
            const today = new Date();
            today.setDate(today.getDate() + 1);
            //Convert to datetime to push to database
            const modifiedDate = new Date(today.setHours(this.closing_time.hours, this.closing_time.minutes));
            //Convert to UTC
            const UTCDate = moment.utc(modifiedDate).format()
            var body_content = {closing_time: UTCDate, day:this.day}
            this.changeTime(body_content)
        },
        updateStartTime(){
            var locationTimeData;
            var objDict = this.location.pickup_times
            Object.keys(objDict).forEach(x => locationTimeData = objDict[x].day === this.day.toString() ? objDict[x]: locationTimeData);
            if(locationTimeData){
                this.opening_time = {
                    hours:moment.utc(locationTimeData.opening_time).local().toDate().getHours(),
                    minutes:moment.utc(locationTimeData.opening_time).local().toDate().getMinutes()
                }
                this.closing_time = {
                    hours:moment.utc(locationTimeData.closing_time).local().toDate().getHours(),
                    minutes:moment.utc(locationTimeData.closing_time).local().toDate().getMinutes()
                }
            }
        },
    },
    mounted(){
        this.week_of_day = days[this.day.toString()]
        this.updateStartTime()
    },
    watch:{
        day: function(){
            this.updateStartTime()
        }
    }
}
</script>
<style scoped>
.time-picker-wrapper{
    display:flex;
    gap:1em;
    flex-direction: row;
    margin-bottom:1em;
}
</style>