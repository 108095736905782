<template>
    <div class="cart-list-display" v-if="products">
        <back-button/>
        <product-display-component @click="fetchProducts" v-for="item in products" :key="item" :given_product="item" />
    </div>
    <Transition>
    <div class="payment-total module" v-if="total_price && getQuantity > 0" :class="{ dis: loading }">
        <div>
            <div class="sub-totals">
                <p>Subtotal</p>
                <p>${{sub_total_price}}</p>
            </div>
            <div class="sub-totals" v-if="sales_price">
                <p>Sales tax</p>
                <p>${{sales_price}}</p>
            </div>
            <div class="sub-totals" v-if="delivery_price">
                <p></p>
                <p></p>
            </div>
        </div>
        <div class="total">
            <p>Total</p>
            <p>${{total_price}}</p>
        </div>
        <v-button @click="show_payment_modal">Checkout</v-button>
    </div>
    </Transition>
    <div>
        <vue-final-modal v-model="payment_modal_show" classes="modal-container" content-class="modal-content">
            <span class="modal__title">Checkout</span>
            <div class="modal__content" v-if="payment_modal_show">
                <checkout-modal :key="cart_products" :products="cart_products"/>
            </div>
        </vue-final-modal>
    </div>
</template>

<script>
import axios from "axios"
import ProductDisplayComponent from "@/components/product/ProductDisplayComponent.vue"
import store from '@/store/index.js';
import CheckoutModal from '@/components/checkout/CheckoutModal.vue';
import BackButton from '@/components/misc/BackButton.vue';
import Button from '@/components/misc/ButtonComponent'
import constants from "@/apiConstants.js"

import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

export default {
  name: 'cart-list',
  components: {
    ProductDisplayComponent,
    CheckoutModal,
    VueFinalModal,
    ModalsContainer,
    BackButton,
    "v-button": Button,
  },
  data(){
    return{
        products: null,
        payment_modal_show: false,
        cart_products: null,
        total_price: null,
        sub_total_price: null,
        sales_price: null,
        delivery_price: null,
        loading:true,
    }
  },
  methods: {
    show_payment_modal(){ this.payment_modal_show=true },
    hide_payment_modal(){ this.payment_modal_show=false },
    async fetchProducts(){
        this.cart_products = store.getters["cartModule/getItems"];
        var productList = []
        for(var k in this.cart_products){
            try{
                
                const url = constants.API_BASE + constants.product.BASE + k
                const response = await axios.get(url);
                const results = response.data
                productList.push(results);
                this.calculateCost();
            }catch(err){
                if (err.response) {
                    // client received an error response (5xx, 4xx)
                    console.log("Server Error:", err)
                } else if (err.request) {
                    // client never received a response, or request never left
                    console.log("Network Error:", err)
                } else {
                    console.log("Client Error:", err)
                }
            };
        }
        this.products = productList
    },
    redirect(){

    },
    async calculateCost(){
        this.loading=true
        const response = await fetch(constants.API_BASE+"payment/calculate_cost/", {
            method: "POST",
            headers: {
            "Content-Type": "application/json"
            },
            
            body: JSON.stringify({ "products": this.cart_products })
        });
        var data = await response.json();
        this.client_secret = data.client_secret;
        this.order_id = data.order_uuid;
        this.total_price = data.total_price;
        this.sub_total_price = data.sub_total_price
        this.loading=false
    },
  },
  computed:{
    getQuantity(){
        var total = store.getters["cartModule/getTotalQuantity"] 
        return total;
    }
  },
  mounted(){
    this.fetchProducts();
  },
}
</script>

<style scoped>

.modal__content.flex{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:1em;
    overflow-y: auto;
}

.dis{
  opacity:0.5;
  cursor:default;
  pointer-events: none;
}

.cart-list-display{
    display:flex;
    flex-direction: column;
    align-content: center;
    flex:1 1 auto;
    padding:4em 0em;
    text-align: center;
    gap:2em;
    justify-content: center;
    align-items: center;
    padding-bottom:300px;
}

.payment-total{
    display:flex;
    flex-direction: column;
    flex:1 1 auto;
    bottom:25px;
    left:50%;
    transform: translate(-50%, 0%);
    width:80%;
    max-width: 450px;
    position:fixed;
    padding:2em;
    margin:2em 0em;
    gap:2em;
    background-color:#E4E4E4;
}
.payment-total .sub-totals, .payment-total .total{
    color: #c5c5c5;
    font-weight:800;
    display:flex;
    flex-direction: row;
    flex:1 1 auto;
    justify-content: space-between;
    font-size:16px;
}
.payment-total .total{
    color:black;
    font-size:20px;
}
</style>