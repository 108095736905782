export default {
    API_BASE: "https://munchmore-api-518c846af5fa.herokuapp.com/",
    vendor:{
        BASE: 'vendor/'
    },
    account:{
        BASE: 'account/'
    },
    location:{
        BASE:'location/',
        UPDATE_TIMES: '/update_times/',
        GET_ORDERS: '/get_orders/',
        UPDATE_PICKUP_INSTRUCTION: '/update_pickup_instruction/',
        GET_TOTAL_SALES: '/get_total_sales/',
    },

    order:{
        BASE: 'order/'
    },
    product:{
        BASE: "product/",
        // Usage - product_id + UPDATE_TITLE
        UPDATE_TITLE: "/update_title/",
        UPDATE_PRICE: "/update_price/",
        UPDATE_IMAGE: "/update_image/",
        UPDATE_QUANTITY: "/update_quantity/",
        UPDATE_DESCRIPTION: "/update_description/",
    },

    payment:{
        BASE:'payment/',
        CREATE_PAYMENT_INTENT: 'payment/create_payment_intent/'     
    },

    STRIPE_PUBLISHABLE_KEY: "asdasd"
    
    
 }